import styled from "styled-components";
import FlexBox from "design-system/src/Components/FlexBox/FlexBox";
import Typography from "design-system/src/Foundation/Typography/Typography";
import { defaultTheme } from "design-system/src/Themes/defaultTheme";

export const LoyaltyOptInStepBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #f8f4f0;
`;

export const LoyaltyOptInStepWrapper = styled(FlexBox)((props) => {
  return {
    maxHeight: 600,
    margin: "auto",
    ".logo-my-sushi-shop": {
      display: "block",
      margin: "-1em auto 0",
      minHeight: 56,
    },
    ".loyalty-opt-in-list": {
      margin: "auto",
      flex: 1,
      gap: "1.5em",
      padding: "1em 0",
      display: "flex",
      justifyContent: "space-evenly",
    },
    ".loyalty-opt-in-list__item": {
      display: "flex",
      gap: ".5em",
      alignItems: "center",
      i: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: "1.8em",
        height: "1.8em",
        borderRadius: "100%",
        background: "#fff",
        margin: "auto 0",
        fontSize: "1.25em",
      },
      span: {
        margin: "-4px 0 0",
      },
    },
    "@media (max-width: 992px)": {
      maxHeight: "calc(-170px + 100svh)",
      margin: 0,
      gap: "min(1.5vh, 12px)",
      ".loyalty-opt-in-title": {
        fontSize: "min(3.5vh, 24px)",
        lineHeight: "1.25em",
        maxWidth: "90%",
        margin: "auto",
      },
      ".ro-typography--h5": {
        fontSize: "min(3vh, 24px)",
        lineHeight: "1.25em",
      },
      ".ro-typography--ui3": {
        fontSize: "min(2vh, 14px)",
        lineHeight: "1.25em",
      },
      ".loyalty-opt-in-list": {
        flexDirection: "column",
        gap: "max(0.5vh, 6px)",
        padding: 0,
      },
      ".loyalty-opt-in-list__item": {
        fontSize: "max(3vh, 16px)",
        lineHeight: "1.25em",
      },
    },
  };
});

export const LoyaltyOptInMentions = styled(Typography)({
  "& a": {
    color: defaultTheme.color.champagne,
  },
});
